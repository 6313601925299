import {Fragment, useCallback} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {confirmPciDssAudit} from "@app/Branch/api/branchApi";
import {ConfirmPciDssAuditRequest} from "@app/Branch/model/BranchRequestsAndResponses";
import {useManualPostTo} from "@app/BranchEvent/components/ManualPostLink/useManualPostTo";
import {PciDssAuditRequiredBranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {ModalBranchEventComponent} from "@app/BranchEvent/model/ModalBranchEventComponent";
import {Button} from "@common/components/Button/Button";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {TranslationWithHtmlParam} from "@common/components/TranslationWithHtmlParam/TranslationWithHtmlParam";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";

import './pciDssAuditRequiredBranchEventModal.scss';

export const PciDssAuditRequiredBranchEventModal: ModalBranchEventComponent<PciDssAuditRequiredBranchEvent> = ({onClose}) => {
    const {t} = useTranslation();
    const {api, router} = useAppContext();

    const form = useForm<ConfirmPciDssAuditRequest>({
        defaultValues: {operatorTrainingConfirmed: false, terminalAppearanceChecked: false, terminalHardwareChecked: false, transactionBehaviourChecked: false},
        mode: 'onSubmit',
    });

    const showModalErrorToast = useShowErrorToast(modalToast);

    const onSubmit = useCallback((request: ConfirmPciDssAuditRequest) => {
        if (!request.terminalAppearanceChecked
            || !request.terminalHardwareChecked
            || !request.transactionBehaviourChecked
            || !request.operatorTrainingConfirmed
        ) {
            modalToast.error(t('viewPciDssAuditConfirm:form.required'));
            return;
        }

        confirmPciDssAudit(request, api)
            .then(() => {
                onClose();
                appToast.success(t('viewPciDssAuditConfirm:message.success'));
            })
            .catch((error: ApiError) => {
                showModalErrorToast(error);
            })
    }, [api, onClose, showModalErrorToast, t]);

    const manualPostTo = useManualPostTo();

    const terminalManualPostTo = manualPostTo('support:whatToDoArticle.paymentTerminalCheck');
    const terminalManualHref = terminalManualPostTo ? router.href(terminalManualPostTo) : '#';

    const trainingManualPostTo = manualPostTo('support:whatToDoArticle.paymentOperatorTraining');
    const trainingManualHref = trainingManualPostTo ? router.href(trainingManualPostTo) : '#';

    return <BaseModal
        show={true}
        onHide={onClose}
        header={t('viewPciDssAuditConfirm:title')}
        footer={<Fragment>
            <Button variant="secondary" onClick={onClose}>{t('viewPciDssAuditConfirm:action.postpone')}</Button>
            <Button variant="primary" onClick={form.handleSubmit(onSubmit)}>
                {t('viewPciDssAuditConfirm:action.confirm')}
            </Button>
        </Fragment>}
        size="lg"
        height="auto"
        className="pci-dss-audit-required-branch-event-modal"
    >
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <h3>{t('viewPciDssAuditConfirm:terminal.header')}</h3>
            <TranslationWithHtmlParam
                paramName={['manualLink', 'emailLink']}
                translationKey="viewPciDssAuditConfirm:terminal.instructions"
                t={t}
                className="pci-dss-audit-required-branch-event-modal__instructions"
            >
                <a href={terminalManualHref} target="_blank">
                    <strong>{t('viewPciDssAuditConfirm:terminal.manual')}</strong>
                </a>
                <a href={`mailto:${t('viewPciDssAuditConfirm:terminal.contactEmailAddress')}`} target="_blank">
                    {t('viewPciDssAuditConfirm:terminal.contactEmailAddress')}
                </a>
            </TranslationWithHtmlParam>
            <p>{t('viewPciDssAuditConfirm:terminal.formHeader')}</p>
            <FormGroup
                form={form}
                name="terminalAppearanceChecked"
                type="checkbox"
                label={t('viewPciDssAuditConfirm:form.terminalAppearanceChecked.label')}
            />
            <FormGroup
                form={form}
                name="terminalHardwareChecked"
                type="checkbox"
                label={t('viewPciDssAuditConfirm:form.terminalHardwareChecked.label')}
            />
            <FormGroup
                form={form}
                name="transactionBehaviourChecked"
                type="checkbox"
                label={t('viewPciDssAuditConfirm:form.transactionBehaviourChecked.label')}
            />
            <h3>{t('viewPciDssAuditConfirm:training.header')}</h3>
            <TranslationWithHtmlParam
                paramName="manualLink"
                translationKey="viewPciDssAuditConfirm:training.instructions"
                t={t}
                className="pci-dss-audit-required-branch-event-modal__instructions"
            >
                <a href={trainingManualHref} target="_blank">
                    <strong>{t('viewPciDssAuditConfirm:training.manual')}</strong>
                </a>
            </TranslationWithHtmlParam>
            <FormGroup
                form={form}
                name="operatorTrainingConfirmed"
                type="checkbox"
                label={t('viewPciDssAuditConfirm:form.operatorTrainingConfirmed.label')}
            />
        </form>
    </BaseModal>
}
