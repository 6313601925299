import {FC} from "react";
import {UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {
    NewMessageFormFields,
    OutgoingMessageFormData
} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {MessageProcessMode as MessageProcessModeEnum} from "@app/Messaging/model/MessagingRequestsAndResponses";
import {Select, SelectOption} from "@common/components/forms/Select/Select";

export type MessageProcessModeProps = {
    form: UseFormReturn<OutgoingMessageFormData>;
    formId: string;
}

export const MessageProcessMode: FC<MessageProcessModeProps> = ({form, formId}) => {
    const {t} = useTranslation();

    const options: Array<SelectOption> = [];
    for (const processMode in MessageProcessModeEnum) {
        if (Object.prototype.hasOwnProperty.call(MessageProcessModeEnum, processMode) && isNaN(Number(processMode))) {
            const value = MessageProcessModeEnum[processMode] as any as string;
            options.push({value, label: t(`message:tabs.content.new.processModeSelect.item-${value}`)});
        }
    }

    return <Select
        name={NewMessageFormFields.processMode}
        label={t('message:tabs.content.new.processMode')}
        formId={formId}
        options={options}
        form={form}
    />
}
