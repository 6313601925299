import {useManualPostTo} from "@app/BranchEvent/components/ManualPostLink/useManualPostTo";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {FCWithChildren} from "@common/model/FCWithChildren";

export type ManualPostLinkProps = {
    postIdTranslationKey: string;
}

export const ManualPostLink: FCWithChildren<ManualPostLinkProps> = ({children, postIdTranslationKey}) => {
    const manualPostTo = useManualPostTo();
    const to = manualPostTo(postIdTranslationKey);

    if (!to) {
        return null;
    }

    return <AppLink to={to} variant="primary">
        {children}
    </AppLink>
}
