import {observer} from "mobx-react-lite";
import {useEffect} from "react";

import {BranchEventModal} from "@app/BranchEvent/components/BrachEventModal/BranchEventModal";
import {
    CashRegisterBalanceConfirmationRequiredBranchEventModal
} from "@app/BranchEvent/components/CashRegisterBalanceConfirmationRequiredBranchEvent/CashRegisterBalanceConfirmationRequiredBranchEventModal";
import {
    InventoryRequiredBranchEventModal
} from "@app/BranchEvent/components/InventoryRequiredBranchEvent/InventoryRequiredBranchEventModal";
import {
    PacketExpeditionRequiredBranchEventModal
} from "@app/BranchEvent/components/PacketExpeditionRequiredBranchEvent/PacketExpeditionRequiredBranchEventModal";
import {
    PacketsPresenceReviewRequiredBranchEventModal
} from "@app/BranchEvent/components/PacketsPresenceReviewRequiredBranchEvent/PacketsPresenceReviewRequiredBranchEventModal";
import {
    PasswordChangeRequiredBranchEventModal
} from "@app/BranchEvent/components/PasswordChangeRequiredBranchEvent/PasswordChangeRequiredBranchEventModal";
import {
    PciDssAuditRequiredBranchEventModal
} from "@app/BranchEvent/components/PciDssAuditRequiredBranchEvent/PciDssAuditRequiredBranchEventModal";
import {useBranchEventMessage} from "@app/BranchEvent/hooks/useBranchEventMessage";
import {BaseBranchEvent} from "@app/BranchEvent/model/BaseBranchEvent";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";
import {ModalBranchEventComponent} from "@app/BranchEvent/model/ModalBranchEventComponent";
import {UnknownBranchEventCodeError} from "@app/BranchEvent/model/UnknownBranchEventCodeError";
import {useSystemNotification} from "@app/PDC/hooks/useSystemNotification";
import {useLogError} from "@common/hooks/useLogError";

export const ModalBranchEvent: ModalBranchEventComponent = observer(({event, onClose}) => {
    const logError = useLogError();
    const notify = useSystemNotification();

    const message = useBranchEventMessage(event, true);
    useEffect(() => {
        if (message !== '') {
            notify(message);
        }
    }, [message, notify]);

    switch (event.code) {
        case BranchEventCode.PARCELS_RECEIVE_REQUIRED_COLLECTED:
        case BranchEventCode.PARCELS_RECEIVE_REQUIRED:
        case BranchEventCode.PACKETS_NOT_RECEIVED:
        case BranchEventCode.PACKETS_NOT_RECEIVED_BRANCH_CLOSING:
        case BranchEventCode.LONG_WAITING_DISPATCH:
        case BranchEventCode.UNFINISHED_RECEIVE_PACKETS_EVENT:
        case BranchEventCode.CASH_REGISTER_TODAY_TRANSFER:
        case BranchEventCode.CASH_REGISTER_MANUAL_TRANSFER_REQUIRED:
        case BranchEventCode.CASH_REGISTER_MANUAL_TRANSFER_MISSING:
        case BranchEventCode.COMMISSION_CREATE_INVOICE_NOTIFY:
        case BranchEventCode.PARCELS_NOT_RECEIVED:
        case BranchEventCode.PACKETS_RECEIVE_REQUIRED:
        case BranchEventCode.PARCEL_PACKETS_MISSING:
        case BranchEventCode.INVENTORY_PACKETS_MISSING:
        case BranchEventCode.INVENTORY_MISSING:
        case BranchEventCode.EXPEDITION_PARCEL_PACKETS_MISSING:
        case BranchEventCode.HOLIDAY_BRANCH_HOURS_CONFIRMATION_REQUIRED:
            return <BranchEventModal event={event} onClose={onClose} />
        case BranchEventCode.PACKET_EXPEDITION_REQUIRED:
            return <PacketExpeditionRequiredBranchEventModal event={event} onClose={onClose} />
        case BranchEventCode.PACKETS_PRESENCE_REVIEW_REQUIRED:
            return <PacketsPresenceReviewRequiredBranchEventModal event={event} onClose={onClose} />
        case BranchEventCode.CASH_REGISTER_BALANCE_CONFIRMATION_REQUIRED:
            return <CashRegisterBalanceConfirmationRequiredBranchEventModal event={event} onClose={onClose} />
        case BranchEventCode.INVENTORY_REQUIRED:
            return <InventoryRequiredBranchEventModal event={event} onClose={onClose} />
        case BranchEventCode.PASSWORD_CHANGE_REQUIRED:
            return <PasswordChangeRequiredBranchEventModal event={event} onClose={onClose} />
        case BranchEventCode.PCI_DSS_AUDIT_REQUIRED:
            return <PciDssAuditRequiredBranchEventModal event={event} onClose={onClose} />
        default:
            void logError(new UnknownBranchEventCodeError((event as BaseBranchEvent).code));
            return null;
    }
})
