import {BranchEvent, isInventoryRequiredYearEndVariables} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";

export const useBranchEventTranslationKey = (branchEvent: BranchEvent): string => {
    switch (branchEvent.code) {
        case BranchEventCode.PARCELS_RECEIVE_REQUIRED_COLLECTED: return 'branchEvent:parcelsReceiveRequiredCash';
        case BranchEventCode.PARCELS_RECEIVE_REQUIRED: return 'branchEvent:parcelsReceiveRequired';
        case BranchEventCode.PACKETS_NOT_RECEIVED: return 'branchEvent:packetsNotReceived';
        case BranchEventCode.PACKETS_NOT_RECEIVED_BRANCH_CLOSING: return 'branchEvent:packetsNotReceivedBranchClosing';
        case BranchEventCode.LONG_WAITING_DISPATCH: return 'branchEvent:longWaitingDispatch';
        case BranchEventCode.UNFINISHED_RECEIVE_PACKETS_EVENT: return 'branchEvent:unfinishedReceivePackets';
        case BranchEventCode.CASH_REGISTER_TODAY_TRANSFER: return 'branchEvent:todayTransfer';
        case BranchEventCode.CASH_REGISTER_MANUAL_TRANSFER_REQUIRED: return 'branchEvent:cashRegisterManualTransferRequired';
        case BranchEventCode.CASH_REGISTER_MANUAL_TRANSFER_MISSING: return 'branchEvent:cashRegisterManualTransferMissing';
        case BranchEventCode.CASH_REGISTER_BALANCE_CONFIRMATION_REQUIRED: return 'branchEvent:pleaseConfirmCashRegisterBalance';
        case BranchEventCode.COMMISSION_CREATE_INVOICE_NOTIFY: return 'branchEvent:commissionCreateInvoice';
        case BranchEventCode.PARCELS_NOT_RECEIVED: return 'branchEvent:parcelsNotReceived';
        case BranchEventCode.PACKETS_RECEIVE_REQUIRED: return 'branchEvent:packetsReceiveRequired';
        case BranchEventCode.PARCEL_PACKETS_MISSING: return 'branchEvent:parcelPacketsMissing';
        case BranchEventCode.INVENTORY_REQUIRED:
            if (isInventoryRequiredYearEndVariables(branchEvent.variables)) {
                return 'branchEvent:inventoryRequiredYearEnd';
            } else {
                return 'branchEvent:timeForInventory';
            }
        case BranchEventCode.INVENTORY_PACKETS_MISSING: return 'branchEvent:inventoryPacketsMissing';
        case BranchEventCode.INVENTORY_MISSING: return 'branchEvent:inventoryMissing';
        case BranchEventCode.PACKET_EXPEDITION_REQUIRED: return 'branchEvent:packetExpeditionRequired';
        case BranchEventCode.EXPEDITION_PARCEL_PACKETS_MISSING: return 'branchEvent:expeditionParcelPacketsMissing';
        case BranchEventCode.PACKETS_PRESENCE_REVIEW_REQUIRED: return 'branchEvent:packetsPresenceReviewRequired';
        case BranchEventCode.HOLIDAY_BRANCH_HOURS_CONFIRMATION_REQUIRED: return 'branchEvent:holidayBranchHoursConfirmationRequired';
        case BranchEventCode.PASSWORD_CHANGE_REQUIRED: return 'branchEvent:passwordChangeRequired';
        case BranchEventCode.PCI_DSS_AUDIT_REQUIRED: return 'branchEvent:pciDssAuditRequired';
    }
}
