import {BaseBranchEvent} from "@app/BranchEvent/model/BaseBranchEvent";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";
import {Money} from "@common/model/Money";

export type SimpleBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.PARCELS_RECEIVE_REQUIRED_COLLECTED
        | BranchEventCode.PARCELS_RECEIVE_REQUIRED
        | BranchEventCode.PACKETS_NOT_RECEIVED
        | BranchEventCode.PACKETS_NOT_RECEIVED_BRANCH_CLOSING
        | BranchEventCode.LONG_WAITING_DISPATCH
        | BranchEventCode.UNFINISHED_RECEIVE_PACKETS_EVENT;
}

export type CashRegisterTransferBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.CASH_REGISTER_TODAY_TRANSFER
        | BranchEventCode.CASH_REGISTER_MANUAL_TRANSFER_REQUIRED;
    variables: Money & {
        lastDepositTransactionAt: string|null;
    };
}

export type CashRegisterManualTransferMissingBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.CASH_REGISTER_MANUAL_TRANSFER_MISSING;
    variables: {
        lastDepositTransactionAt: string;
        transactionExpired: boolean;
    };
}

export type CashRegisterBalanceConfirmationRequiredBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.CASH_REGISTER_BALANCE_CONFIRMATION_REQUIRED;
}

export type CommissionCreateInvoiceNotifyBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.COMMISSION_CREATE_INVOICE_NOTIFY;
    variables: Money & {
        limitDate: string;
    }
}

export type ParcelsNotReceivedBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.PARCELS_NOT_RECEIVED;
    variables: {
        cashTransferTime: string;
    }
}

export type PacketsReceiveRequiredBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.PACKETS_RECEIVE_REQUIRED;
    variables: {
        time: string;
    }
}

export type ParcelPacketsMissingBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.PARCEL_PACKETS_MISSING;
    variables: {
        parcelId: string;
        parcelBarcode: string;
        parcelReceivedAt: string|null;
        missingPacketCount: number;
    }
}

export type InventoryRequiredBranchEventDaysVariables = {
    days: number|null;
}

export type InventoryRequiredBranchEventYearEndVariables = {
    isYearEndInventory: boolean,
    limitDate: string;
}

export type InventoryRequiredBranchEventVariables = InventoryRequiredBranchEventDaysVariables | InventoryRequiredBranchEventYearEndVariables;

export type InventoryRequiredBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.INVENTORY_REQUIRED;
    variables: InventoryRequiredBranchEventVariables
}

export const isInventoryRequiredYearEndVariables = (variables: InventoryRequiredBranchEventVariables): variables is InventoryRequiredBranchEventYearEndVariables => {
    return 'isYearEndInventory' in variables && variables.isYearEndInventory;
}

export type InventoryPacketsMissingBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.INVENTORY_PACKETS_MISSING;
    variables: {
        inventoryId: string;
        createdAt: string;
        missingPacketCount: number;
    }
}

export type InventoryMissingBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.INVENTORY_MISSING;
    variables: {
        days: number|null;
    }
}

export type PacketExpeditionRequiredBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.PACKET_EXPEDITION_REQUIRED;
    variables: {
        packetToExpeditionCount: number;
    }
}

export type ExpeditionParcelPacketsMissingBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.EXPEDITION_PARCEL_PACKETS_MISSING;
    variables: {
        parcelId: string;
        parcelBarcode: string;
        parcelSentAt: string|null;
        missingPacketCount: number;
    }
}

export type PacketsPresenceReviewRequiredBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.PACKETS_PRESENCE_REVIEW_REQUIRED;
}

export type HolidayBranchHoursConfirmationRequiredBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.HOLIDAY_BRANCH_HOURS_CONFIRMATION_REQUIRED;
    variables: {
        startDate: string;
        endDate: string;
        holidayDate: string;
        holidayName: string|null;
    }
}

export type PasswordChangeRequiredBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.PASSWORD_CHANGE_REQUIRED;
}

export type PciDssAuditRequiredBranchEvent = BaseBranchEvent & {
    code: BranchEventCode.PCI_DSS_AUDIT_REQUIRED;
}

export type BranchEvent = SimpleBranchEvent
    | CashRegisterTransferBranchEvent
    | CashRegisterManualTransferMissingBranchEvent
    | CashRegisterBalanceConfirmationRequiredBranchEvent
    | CommissionCreateInvoiceNotifyBranchEvent
    | ParcelsNotReceivedBranchEvent
    | PacketsReceiveRequiredBranchEvent
    | ParcelPacketsMissingBranchEvent
    | InventoryRequiredBranchEvent
    | InventoryPacketsMissingBranchEvent
    | InventoryMissingBranchEvent
    | PacketExpeditionRequiredBranchEvent
    | ExpeditionParcelPacketsMissingBranchEvent
    | PacketsPresenceReviewRequiredBranchEvent
    | HolidayBranchHoursConfirmationRequiredBranchEvent
    | PasswordChangeRequiredBranchEvent
    | PciDssAuditRequiredBranchEvent;
