
import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";
import {HttpStatusCode} from "@common/model/HttpStatusCode";

export const microDepotEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/micro-depot',
}

const microDepotKnownErrorCodes = {
    routeNotClosedByDriver: 'routeNotClosedByDriver' as const,
    permissionDenied: {errorCode: 'permissionDenied' as const, statusCode: HttpStatusCode.FORBIDDEN},
}

export const microDepotRouteSummaryEndpoint: Endpoint<'password', keyof typeof microDepotKnownErrorCodes> = {
    method: HttpMethod.GET,
    url: '/micro-depot/route/:password',
    routeParamNames: ['password'],
    knownErrorCodes: microDepotKnownErrorCodes,
    notFoundErrorResponse: true,
}

export const microDepotRouteFinalizationEndpoint: Endpoint<string, keyof typeof microDepotKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/micro-depot/route/finalize',
    knownErrorCodes: microDepotKnownErrorCodes,
}
