import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {getBranchAttributes} from "@app/Branch/api/branchApi";
import {GetBranchAttributesResponse} from "@app/Branch/model/BranchRequestsAndResponses";
import {CashRegisterSettings} from "@app/Branch/pages/BranchSettingsPage/CashRegisterSettings/CashRegisterSettings";
import {DirectionsSettings} from "@app/Branch/pages/BranchSettingsPage/DirectionsSettings/DirectionsSettings";
import {
    WheelchairAccessibilitySettings
} from "@app/Branch/pages/BranchSettingsPage/WheelchairAccessibilitySettings/WheelchairAccessibilitySettings";
import {Loading} from "@common/components/Loading/Loading";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

import './branchSettingsPage.scss';

export const BranchSettingsPage: FC = () => {
    const {t} = useTranslation();
    const {api} = useAppContext();

    const [branchAttributesResponse, setBranchAttributesResponse] = useState<GetBranchAttributesResponse|null|false>(null);

    const showAppErrorToast = useShowErrorToast(appToast);

    const loadBranchAttributes = useCallback(() => {
        getBranchAttributes(api)
            .then((response) => {
                setBranchAttributesResponse(response);
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
                setBranchAttributesResponse(false);
            });
    }, [api, showAppErrorToast]);

    useEffect(() => {
        if (branchAttributesResponse === null) {
            loadBranchAttributes();
        }
    }, [branchAttributesResponse, loadBranchAttributes]);

    return <PageContent className="branch-settings-page">
        <PageHeader subTitle={t('viewBranchSettings:subtitle')}>{t('viewBranchSettings:header')}</PageHeader>
        <Loading active={branchAttributesResponse === null}>
            {branchAttributesResponse && <Fragment>
                <WheelchairAccessibilitySettings
                    wheelchairAccessibility={branchAttributesResponse.attributes.wheelchairAccessibility}
                    updatePending={branchAttributesResponse.updatePendingAttributes.wheelchairAccessibility}
                    onUpdate={loadBranchAttributes}
                />
                <DirectionsSettings
                    directions={branchAttributesResponse.attributes.directions}
                    updatePending={branchAttributesResponse.updatePendingAttributes.directions ? true : undefined}
                    onUpdate={loadBranchAttributes}
                />
                <CashRegisterSettings
                    cashRegister={branchAttributesResponse.attributes.cashRegister}
                    updatePending={branchAttributesResponse.updatePendingAttributes.cashRegister ? true : undefined}
                />
            </Fragment>}
        </Loading>
    </PageContent>

}
