import {observer} from "mobx-react-lite";
import {FC, Fragment, useState} from "react";
import {Dropdown, Nav, NavItem, NavLink} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {useTranslation} from "react-i18next";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {BranchQualityRoute, BranchSettingsRoute, BranchStatsRoute, ManagedStatsRoute} from "@app/Branch/BranchRoutes";
import {BranchCommissionRoute, ManagedCommissionRoute} from "@app/Commission/CommissionRoutes";
import {DevicesTestModal} from "@app/Devices/components/DevicesTestModal/DevicesTestModal";
import {BranchInventoryListRoute, ManagedInventoryRoute} from "@app/Inventory/InventoryRoutes";
import {useShowBenefitsPage} from "@app/Manuals/hooks/useShowBenefitsPage";
import {BenefitsRoute, ManualsIndexRoute, TermsAndConditionsRoute} from "@app/Manuals/ManualsRoutes";
import {MicroDepotOperationalInfoRoute} from "@app/MicroDepot/MicroDepotRoutes";
import {OpeningHoursIndexRoute} from "@app/OpeningHours/OpeningHoursRoutes";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {ParcelListRoute} from "@app/Parcel/ParcelRoutes";
import {ReprintsRoute} from "@app/Print/PrintRoutes";
import {
    BranchCardTransactionRoute, BranchCashRegisterRoute, ManagedCardTransactionRoute,
    ManagedCashRegisterRoute, PartnerCardTransactionRoute
} from "@app/Transaction/TransactionModuleRoutes";
import {Img} from "@common/components/Img/Img";
import {useSingleBranchTo} from "@common/hooks/useSingleBranchTo";
import {EducationLink} from "@common/layouts/SecuredLayout/Header/MainMenu/EducationLink/EducationLink";
import {MainMenuLink} from "@common/layouts/SecuredLayout/Header/MainMenu/MainMenuLink/MainMenuLink";
import {ShelfBarcodesPrintModal} from "@common/layouts/SecuredLayout/Header/MainMenu/ShelfBarcodesPrintModal/ShelfBarcodesPrintModal";

import './mainMenu.scss';

export const MainMenu: FC = observer(() => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const branchDetailTo = useSingleBranchTo();

    const [devicesTestModalOpened, setDevicesTestModalOpened] = useState<boolean>(false);
    const [shelfBarcodesPrintModalOpened, setShelfBarcodesPrintModalOpened] = useState<boolean>(false);
    const showBenefitsPage = useShowBenefitsPage();

    return <Fragment>
        <Nav className="main-menu">
            <NavItem>
                <MainMenuLink to={DashboardRoute} label={t('global:menu.pickUp')} image="ico-dashboard" />
            </NavItem>
            <NavItem>
                <MainMenuLink to={ParcelListRoute} label={t('global:menu.parcels')} image="ico-parcel" />
            </NavItem>
            <Dropdown as={NavItem}>
                <DropdownToggle as={NavLink} title={t('global:menu.payments')}>
                    <Img src="/images/icons/ico/ico-payment.svg" alt={t('global:menu.payments')} />{' '}
                    {t('global:menu.payments')}
                </DropdownToggle>
                <DropdownMenu className="main-menu__payments">
                    <DropdownItem as="div">
                        <MainMenuLink
                            to={branchDetailTo(BranchCashRegisterRoute, ManagedCashRegisterRoute)}
                            label={t('global:menu.cashRegister')}
                            image="ic_cash_24px"
                        />
                    </DropdownItem>
                    <div className="dropdown-divider" />
                    <DropdownItem as="div">
                        <MainMenuLink
                            to={branchDetailTo(BranchCardTransactionRoute, ManagedCardTransactionRoute)}
                            label={t('global:menu.cardPayments')}
                            image="ic_credit_card_24px"
                        />
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <Can I={Action.SHOW} a={Subject.COMMISSION}>
                <NavItem>
                    <MainMenuLink
                        to={branchDetailTo(BranchCommissionRoute, ManagedCommissionRoute)}
                        label={t('global:menu.commission')}
                        image="ico-commission"
                    />
                </NavItem>
            </Can>
            <NavItem>
                <MainMenuLink
                    to={ManualsIndexRoute}
                    label={t('global:menu.manuals')}
                    image="ico-find_in_page_24px"
                    end
                />
            </NavItem>
            <Dropdown as={NavItem}>
                <DropdownToggle as={NavLink} title={t('global:menu.other')}>
                    {t('global:menu.other')}
                </DropdownToggle>
                <DropdownMenu className="main-menu__other">
                    <DropdownItem as="div">
                        <MainMenuLink
                            label={t('global:menu.inventory')}
                            to={branchDetailTo(BranchInventoryListRoute, ManagedInventoryRoute)}
                            image="ico-inventura"
                            activeImage={false}
                        />
                    </DropdownItem>
                    <Can I={Action.SHOW} a={Subject.MICRO_DEPOT}>
                        <div className="dropdown-divider" />
                        <DropdownItem as="div">
                            <MainMenuLink
                                label={t('global:menu.microDepot')}
                                to={MicroDepotOperationalInfoRoute}
                                image="ico-micro-depot"
                                activeImage={false}
                            />
                        </DropdownItem>
                    </Can>
                    <div className="dropdown-divider" />
                    <DropdownItem href="https://client.packeta.com" active={false} className="nav-link" title={t('global:menu.clientSection')}>
                        <Img src="/images/icons/ico/ic_people_24px.svg" alt={t('global:menu.clientSection')} />{' '}
                        {t('global:menu.clientSection')}
                    </DropdownItem>
                    <Can I={Action.ALL} a={Subject.PARTNER_CARD_TRANSACTION}>
                        <div className="dropdown-divider" />
                        <DropdownItem as="div">
                            <MainMenuLink
                                label={t('viewPartnerCardTransaction:title')}
                                to={PartnerCardTransactionRoute}
                                image="ic_credit_card_24px"
                                activeImage={false}
                            />
                        </DropdownItem>
                    </Can>
                    {appContext.dashboardInfo.isSkBranch && <EducationLink />}
                    <div className="dropdown-divider" />
                    <DropdownItem as="div">
                        <MainMenuLink label={t('global:menu.termsAndConditions')} to={TermsAndConditionsRoute} image="ic_description_gray_24px" activeImage={false} />
                    </DropdownItem>
                    <div className="dropdown-divider" />
                    <DropdownItem as="div">
                        <MainMenuLink label={t('global:menu.branchSettings')} to={BranchSettingsRoute} image="ico-branch-settings" activeImage={false} />
                    </DropdownItem>
                    <Can I={Action.SHOW} a={Subject.BRANCH_QUALITY}>
                        <div className="dropdown-divider"/>
                        <DropdownItem as="div">
                            <MainMenuLink label={t('global:menu.branchQuality')} to={BranchQualityRoute} image="ico-award" activeImage={false} />
                        </DropdownItem>
                    </Can>
                    <Can I={Action.SHOW} a={Subject.PACKET_STATISTIC}>
                        <div className="dropdown-divider"/>
                        <DropdownItem as="div">
                            <MainMenuLink label={t('global:menu.packetStatistic')} to={branchDetailTo(BranchStatsRoute, ManagedStatsRoute)} image="ico-chart" activeImage={false} />
                        </DropdownItem>
                    </Can>
                    <div className="dropdown-divider"/>
                    <DropdownItem as="div">
                        <MainMenuLink label={t('reprint:title.general')} to={ReprintsRoute} image="ic_print_gray_24px" activeImage={false} />
                    </DropdownItem>
                    <div className="dropdown-divider" />
                    <DropdownItem as="div">
                        <MainMenuLink label={t('global:menu.openingHours')} to={OpeningHoursIndexRoute} image="ico-opening-hours" activeImage={false} />
                    </DropdownItem>
                    <div className="dropdown-divider" />
                    <DropdownItem onClick={() => setDevicesTestModalOpened(true)} className="nav-link" title={t('global:menu.hardwareTest')}>
                        <Img src="/images/icons/ico/ic_perm_device_information_24px.svg" alt={t('global:menu.hardwareTest')} />{' '}
                        {t('global:menu.hardwareTest')}
                    </DropdownItem>
                    <div className="dropdown-divider" />
                    <DropdownItem onClick={() => setShelfBarcodesPrintModalOpened(true)} className="nav-link" title={t('viewShelfBarcodesPrint:title')}>
                        <Img src="/images/icons/ico/ic_qr_code_2-24px.svg" alt={t('viewShelfBarcodesPrint:title')} />{' '}
                        {t('viewShelfBarcodesPrint:title')}
                    </DropdownItem>
                    {showBenefitsPage && <Fragment>
                        <div className="dropdown-divider" />
                        <DropdownItem as="div">
                            <MainMenuLink label={t('global:menu.benefits')} to={BenefitsRoute} image="ico-benefits" activeImage={false} />
                        </DropdownItem>
                    </Fragment>}
                </DropdownMenu>
            </Dropdown>
        </Nav>
        <DevicesTestModal show={devicesTestModalOpened} onHide={() => setDevicesTestModalOpened(false)} />
        <ShelfBarcodesPrintModal
            show={shelfBarcodesPrintModalOpened}
            onHide={() => setShelfBarcodesPrintModalOpened(false)}
            supportBranchId={appContext.user.supportBranchId}
        />
    </Fragment>
})
