import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export const detailEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/branch/detail/:branchId',
    routeParamNames: ['branchId'],
}

export const eventListEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/branch/event/list',
}

export const getBranchAttributesEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/branch/attributes',
}

export const branchPacketCountStatsEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/stats/branch-packet-counts/:branchId',
    routeParamNames: ['branchId'],
}

export const branchQualityEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/branch/quality',
}

export const branchQualityDetailEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/branch/quality/detail'
}

export const confirmPciDssAuditEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/branch/pci-dss-audit/confirm',
}

export const managedBranchListEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/branch/managed/list',
}

export const updateBranchAttributesEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/branch/attributes',
}

export const zPointSearchEndpoint: Endpoint<'searchStr'> = {
    method: HttpMethod.GET,
    url: '/branch/z-point/list/:searchStr',
    routeParamNames: ['searchStr'],
}

export const zPointCountriesEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/branch/z-point-country/list',
}
