import {FC, Fragment, useCallback} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {printPacketLabels} from "@app/Print/api/printApi";
import {PrintableTypes} from "@app/Print/model/PrintRequestsAndResponses";
import {ReprintButton} from "@common/components/ReprintButton/ReprintButton";
import {usePrint} from "@common/hooks/usePrint";
import {PrintResult, PrintResultType} from "@common/model/PrintResult";

export type SurpriseCodeConsignmentReceiptPrintProps = {
    barcode: string;
    printResult: PrintResult|undefined;
    onPrinted: (printResult: PrintResult|undefined) => void;
    onSubmitting: (submitting: boolean) => void;
}

export const SurpriseCodeConsignmentReceiptPrint: FC<SurpriseCodeConsignmentReceiptPrintProps> = ({barcode, onPrinted, onSubmitting, printResult}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const handlePrint = usePrint(true);

    const callPrint = useCallback(() => {
        onSubmitting(true);
        void handlePrint(printPacketLabels({barcodes: [barcode], type: PrintableTypes.CONSIGNMENT_RECEIPT}, appContext.api))
            .then((response) => {
                if (response) {
                    onPrinted(
                        response.prints.find((_print) => _print.type === PrintResultType.CONSIGNMENT_RECEIPT)
                    );
                }
            })
            .finally(() => {
                onSubmitting(false);
            })
    }, [onSubmitting, handlePrint, barcode, appContext.api, onPrinted]);

    const hasReprintId = printResult && printResult.reprintId !== null;

    return <Fragment>
        {hasReprintId && <ReprintButton printResult={printResult} toastInModal={true}>
            {t('viewSurpriseCode:action.reprintDelivery')}
        </ReprintButton>}
        {!hasReprintId && <Button onClick={callPrint} variant="secondary">
            {t('viewSurpriseCode:action.printDelivery')}
        </Button>}
    </Fragment>
}
