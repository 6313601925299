export enum Subject {
    ALL = 'all',
    BILLING_INFORMATION = 'billingInformation',
    BRANCH_HOURS = 'branchHours',
    PARTNER_CARD_TRANSACTION = 'partnerCardTransaction',
    CARD_TRANSACTION = 'cardTransaction',
    BRANCH_QUALITY = 'branchQuality',
    COMMISSION = 'commission',
    COMMISSION_INVOICE = 'commissionInvoice',
    COMMISSION_TARIFF = 'commissionTariff',
    INCOMING_MESSAGE = 'incomingMessage',
    OUTGOING_MESSAGE = 'outgoingMessage',
    MICRO_DEPOT = 'microDepot',
    MICRO_DEPOT_ROUTE = 'microDepotRoute',
    WEB_PAYMENT_LINK = 'webPaymentLink',
    PACKET_STATISTIC = 'packetStatistic',
}

export enum Action {
    ALL = 'manage',
    CREATE = 'create',
    DELETE = 'delete',
    DOWNLOAD = 'download',
    CANCEL_UNFINISHED = 'cancelUnfinished',
    MANAGE = 'administer',
    READ = 'read',
    REGISTRY_UPDATE = 'registryUpdate',
    SEND = 'send',
    SEND_BULK = 'sendBulk',
    SHOW = 'show',
}
