import {receiveClaimFromCustomer} from "@app/Packet/api/packetApi";
import {PasswordHandlingComponent} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {ReprintablePasswordModal} from "@app/Packet/pages/DashboardPage/SearchForm/ReprintablePasswordModal/ReprintablePasswordModal";
import {SearchFormResult} from "@app/Packet/pages/DashboardPage/SearchForm/SearchFormTypes";

export const ClaimFromCustomerPasswordModal: PasswordHandlingComponent = ({onError, onFinish, onVerified, password}) => {
    return <ReprintablePasswordModal
        apiMethod={receiveClaimFromCustomer}
        password={password}
        translationDomain="viewClaimFromCustomer"
        onFinish={() => onFinish(SearchFormResult.RETURN_PACKET)}
        onError={onError}
        onVerified={onVerified}
    />
}
