import {FC, KeyboardEvent, useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {SearchFormState} from "@app/Packet/pages/DashboardPage/SearchForm/SearchFormTypes";
import {useSearchForm} from "@app/Packet/pages/DashboardPage/SearchForm/useSearchForm";
import {InputFlag} from "@common/components/forms/FormGroup/InputFlag/InputFlag";
import {SearchInput, SearchInputData} from "@common/components/forms/SearchInput/SearchInput";

import './searchForm.scss';

export const SearchForm: FC = () => {
    const {t} = useTranslation();

    const {
        doSearch,
        form,
        handleElement,
        isCorrectButUsed,
        searchFormState,
    } = useSearchForm();

    const onSubmit = useCallback(({query}: SearchInputData) => {
        doSearch(query);
    }, [doSearch]);

    const onKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            doSearch.flush();
        }
    }, [doSearch]);

    useEffect(() => {
        if (searchFormState === SearchFormState.DEFAULT) {
            form.setFocus('query')
        }
    }, [form, searchFormState]);

    const formId = 'searchForm';

    return <div className="search-form">
        <form id={formId} onChange={form.handleSubmit(onSubmit)}>
            <SearchInput
                label={<div className="search-form__heading">
                    <strong>{t('global:passwordBox.title')}</strong>{' '}{t('global:passwordBox.helper')}
                </div>}
                form={form}
                onSubmit={onSubmit}
                formId={formId}
                inputOptions={{
                    id: 'input-password',
                    placeholder: t('global:passwordBox.enterPassword.label'),
                    autoComplete: 'off',
                    className: 'mt-3 mx-auto',
                    onKeyPress,
                }}
            />
            {searchFormState === SearchFormState.SEARCHING && <InputFlag
                message={`${t('global:passwordBox.verifyingPassword')}…`}
                type="loading"
            />}
            {searchFormState === SearchFormState.PASSWORD_INVALID && <InputFlag
                message={t(`global:passwordBox.${isCorrectButUsed ? 'correctPasswordButAlreadyUsed' : 'wrongPassword'}`)}
                type="error"
                xid="search-form-invalid-password"
            />}
        </form>
        {handleElement}
    </div>
}
