import classNames from "classnames";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {ReaderJobResults} from "@app/Devices/components/ReaderModal/components/ReaderJobResults/ReaderJobResults";
import {ReaderJobTitleType} from "@app/Devices/components/ReaderModal/utils/resolveSubSection";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderPacketResult} from "@app/Devices/model/ReaderPacketResult";
import {ReaderProcessResultType} from "@app/Devices/model/ReaderProcessResult";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {PacketStatusBadge} from "@common/components/Badges/PacketStatusBadge/PacketStatusBadge";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";
import {packetWholeName} from "@common/utils/packetWholeName";

import './readerJobItemPacketsGrid.scss';

export type ReaderJobItemPacketsGridProps = {
    job: ReaderJob;
    packets: ReaderPacketResult[];
}

export const ReaderJobItemPacketsGrid: FC<ReaderJobItemPacketsGridProps> = ({job, packets}) => {
    const {t} = useTranslation();

    if (packets.length === 0) {
        return null;
    }

    const rowClassName = (packet: ReaderPacketResult) => {
        if (packet.results && packet.results.length > 0) {
            for (const result of packet.results) {
                if ([ReaderProcessResultType.WARNING, ReaderProcessResultType.ERROR].includes(result.type)) {
                    return result.type;
                }
            }
        }
         return '';
    }

    return <GridTable className="reader-job-item-packets-grid">
        <thead>
            <tr>
                <th>
                    {t('packet:property.barcodeAlt')}
                    <div className="reader-job-item-packets-grid__barcode-status">
                        {t('packet:property.status')}
                    </div>
                </th>
                <th className="reader-job-item-packets-grid__consign-date">
                    {t('packet:property.consignDate.label')}
                </th>
                <th className="reader-job-item-packets-grid__sender">{t('packet:property.senderAlt')}</th>
                <th className="reader-job-item-packets-grid__order-number">
                    {t('packet:property.number')}
                </th>
                <th>{t('packet:property.recipient')}</th>
                <th className="reader-job-item-packets-grid__address">{t('packet:property.addressAbbr')}</th>
                <th className="reader-job-item-packets-grid__status">{t('packet:property.status')}</th>
                <th>{t('reader:actionOrResult')}</th>
            </tr>
        </thead>
        <tbody>
            {packets.map((packet, index) => {
                const packetData = packet.packetData && !(packet.packetData instanceof Array) ? packet.packetData : null;
                const barcode = packetData && packetData.barcode ? packetData.barcode : null;

                return <tr
                    key={barcode || index}
                    className={classNames('reader-job-item-packets-grid__row', `reader-job-item-packets-grid__row_${rowClassName(packet)}`)}
                >
                    <td>
                        {dashWhenEmpty(barcode && <FormattedPacketBarcode barcode={barcode} />)}
                        {packetData && packetData.externalTrackingCode && <div>{packetData.externalTrackingCode}</div>}
                        {packetData && packetData.status && <div className="reader-job-item-packets-grid__barcode-status">
                            <PacketStatusBadge status={packetData.status} />
                        </div>}
                    </td>
                    <td className="reader-job-item-packets-grid__consign-date">
                        {dashWhenEmpty(packetData && packetData.consignedDate && <DateFormatter date={packetData.consignedDate} />)}
                    </td>
                    <td className="reader-job-item-packets-grid__sender">
                        {dashWhenEmpty(packetData && packetData.clientName)}
                    </td>
                    <td className="reader-job-item-packets-grid__order-number">
                        {dashWhenEmpty(packetData && packetData.orderNumber)}
                    </td>
                    <td>
                        {dashWhenEmpty(packetData && packetWholeName({name: packetData.recipientName, surname: packetData.recipientSurname}))}
                    </td>
                    <td className="reader-job-item-packets-grid__address">
                        {dashWhenEmpty(packetData && packetData.destinationName)}
                    </td>
                    <td className="reader-job-item-packets-grid__status">
                        {dashWhenEmpty(packetData && packetData.status && <PacketStatusBadge status={packetData.status} />)}
                    </td>
                    <td>
                        <ReaderJobResults results={packet.results} job={job} titleType={ReaderJobTitleType.PACKET} />
                    </td>
                </tr>
            })}
        </tbody>
    </GridTable>

}
