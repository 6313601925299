import {createRequestFromData} from "@app/Messaging/components/NewMessage/common/MessageAttachment/createRequestFromData";
import {OutgoingMessageFormData} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {CreateOutgoingMessageRequest} from "@app/Messaging/model/MessagingRequestsAndResponses";
import {fileToString} from "@common/utils/fileToString";

export const createOutgoingMessageRequest = async (values: OutgoingMessageFormData): Promise<CreateOutgoingMessageRequest> => {
    const baseRequest = await createRequestFromData(values);

    const request: CreateOutgoingMessageRequest = {
        ...baseRequest,
        type: values.type,
        processMode: values.processMode,
        accessMode: values.accessMode,
        publishAt: values.publishAt,
        hideAt: values.hideAt,
        recipientIds: [],
    }

    if (values.repliesToMessageId) {
        request.repliesToMessageId = values.repliesToMessageId;
    }

    switch (values.recipientType) {
        case "upload":
            if (values.csvRecipients) {
                const file = values.csvRecipients.item(0);
                if (file) {
                    const fileString = await fileToString(file);
                    request.recipientIds = fileString.split(/\r?\n/).filter((recipientId) => {
                        return recipientId !== '';
                    });
                }
            }
            break;
        case "region":
            request.recipientCountryCodes = [values.recipientCountryCode];
            break;
        default:
            request.recipientIds.push(values.oneRecipientId);
            break;
    }

    return request;
}
